import Layout from '../../components/Layout';
import ProjectList from '../../components/ProjectList';
import ProjectsBanner from '../../components/ProjectsBanner';
import React from 'react';

const ProjectsPage = () => (
  <Layout>
    <div className="projects">
      <ProjectsBanner />
      <ProjectList />
    </div>
  </Layout>
);

export default ProjectsPage;
