import React from "react";

class ProjectsBanner extends React.Component {
  render() {
    return (
      <div
        className="projects-banner"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="middling-column-container is-flex-desktop section"
          style={{
            maxWidth: "1440px",
            alignItems: "center",
            flexGrow: "1"
          }}
        >
          <section className=" ">
            <div className="columns">
              <div className="column">
                <h1 className="title is-1">
                  <span>Simiancraft Does It All</span>
                </h1>
                <h2 className="subtitle is-5">
                  Mobile apps, Dataviza, Video Games and Custom Software
                </h2>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ProjectsBanner;
