import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';
import PropTypes from 'prop-types';

class ProjectList extends Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    const postPreview = posts.map((post, i) => (
      <div
        key={i}
        className="projects-list-item columns is-vcentered is-flex-tablet"
        style={{
          justifyContent: 'center'
        }}
      >
        <div
          className="middling-column-container is-flex-desktop section"
          style={{
            maxWidth: '1440px',
            alignItems: 'center',
            flexGrow: '1'
          }}
        >
          <div className="column is-one-third-desktop">
            <div className="">
              <div className="title is-2 is-uppercase">
                {post.node.frontmatter.title}
              </div>
              <div className="subtitle is-5 is-uppercase">
                {post.node.frontmatter.elevatorPitch}
              </div>
            </div>
            <hr />
            <div className="is-6">{post.node.frontmatter.date}</div>
            <div className="is-6">
              {post.node.frontmatter.tags.map(tag => `#${tag} `)}
            </div>
          </div>
          <div className="column is-two-thirds-desktop">
            <Img
              className="image is-2by1"
              fluid={post.node.frontmatter.listViewImage.childImageSharp.fluid}
              style={{ maxHeight: '400px' }}
            />
          </div>
        </div>
      </div>
    ));
    return <section className="projects-list">{postPreview}</section>;
  }
}

ProjectList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

const ProjectListQuery = () => (
  <StaticQuery
    query={graphql`
      query ProjectListQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: { frontmatter: { templateKey: { eq: "project-post" } } }
          limit: 1000
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                tags
                title
                templateKey
                date(formatString: "MMMM YYYY")
                elevatorPitch
                problem
                solution
                isFeaturedPiece
                listViewImage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ProjectList data={data} count={count} />}
  />
);

export default ProjectListQuery;
